import React from "react";
import {withRouter} from "react-router-dom";
import {Table} from "../../../theme/css/Global";

function PrivacyPolicy() {
	return (
		<React.Fragment>
			<div className="container pt-5 pb-5">
				<div className="row">
					<div className="col-12">
						<h2 className="pb-5">Privacy Policy</h2>
						<p>
						Last updated: November 6, 2024
						</p>
						<p>
						We take your privacy very seriously. Please read this Privacy Policy carefully as it contains important information on how and why we collect, store, use, and share your personal information when you use our website (the “Site”). It also explains your rights in relation to your personal information and how to contact us.

						</p>
						<p>
						You must be at least 18 years old to use the Site.
						</p>
						<p>By accessing, using, or submitting information to the Site, you agree to this Privacy Policy and our Terms & Conditions.

						</p>
						<h4 className="pb-2 pt-3">Personal Information We Collect About You</h4>
						<p>
						We have collected personal information about consumers within the preceding 12 months as noted here:
						</p>
						<Table cellSpacing="0" cellPadding="8">
							<thead>
							<tr>
								<th>
									<p><strong>Categories of (PI)</strong></p>
								</th>
								<th>
									<p><strong>Categories of Sources from which the Personal Information is collected from</strong>
									</p>
								</th>
								<th>
									<p><strong>Business Purpose </strong></p>
								</th>
								<th>
									<p><strong>Length of Retention </strong></p>
								</th>
							</tr>
							</thead>
							<tbody>

							<tr>
								<td>
									<p>Identifiers (e.g., a real name, alias, postal address, unique personal identifier, online identifier, Internet Protocol address, email address, account name, social security number, driver’s license number, passport number, or other similar identifiers), or information that identifies, relates to, describes, or is capable of being associated with, a particular individual
</p>
								</td>
								<td>
									<p>☐ not applicable (not collected)</p>
									<p>☒ Consumer directly;</p>
									<p>☐ Advertising networks;</p>
									<p>☐ ISPs;</p>
									<p>☒ Data analytics providers;</p>
									<p>☐ Gov’t entities;</p>
									<p>☐ Operating systems and platforms;</p>
									<p>☐ Social networks;</p>
									<p>☒ Data brokers;</p>
									<p>☒ Other third parties (signal providers)</p>
								
								</td>
								<td>
									<p>☒Auditing related to counting ad impressions to unique visitors, verifying positioning and quality of ad impressions, and auditing compliance with this specification and other standards</p>
									<p>☒ Helping to ensure security and integrity;</p>
									<p>☒ Debugging to identify and repair errors that impair existing intended functionality.</p>
									<p>☐ Short-term, transient use, provided that the personal information is not disclosed to another third party and is not used to build a profile about a consumer or otherwise alter an individual consumer’s experience outside the current interaction, including, but not limited to, the contextual customization of ads shown as part of the same interaction.</p>
									<p>☐ Performing services on behalf of the business or service provider, including maintaining or servicing accounts, providing customer service, processing or fulfilling orders and transactions, verifying customer information, processing payments, providing financing, providing advertising or marketing services, providing analytic services, or providing similar services on behalf of the business or service provider.</p>
									<p>☐ Undertaking internal research for technological development and demonstration.</p>
									<p>☐ Undertaking activities to verify or maintain the quality or safety of a service or device that is owned, manufactured, manufactured for, or controlled by the business, and to improve, upgrade, or enhance the service or device that is owned, manufactured, manufactured for, or controlled by the business. </p>
								</td>
								<td>
									<p>Retained indefinitely, unless the consumer requests that their information be deleted</p>
								</td>
							</tr>
							<tr>
								<td>
									<p>Characteristics of protected classifications under California or federal law.</p>
								</td>
								<td>
									<p>Not applicable</p>
								</td>
								<td>
									<p>Not applicable</p>
								</td>
								<td>
									<p>Not applicable</p>
								</td>
							</tr>
							<tr>
								<td>
									<p>Commercial information (e.g., records of personal property, products or services purchased, obtained, or considered, or other purchasing or consuming histories or tendencies)</p>
								</td>
								<td>
									<p>Not applicable</p>
								</td>
								<td>
									<p>Not applicable</p>
								</td>
								<td>
									<p>Not applicable</p>
								</td>
								
							</tr>
							<tr>
								<td>
									<p>Biometric information</p>
								</td>
								<td>
									<p>Not applicable</p>
								</td>
								<td>
									<p>Not applicable</p>
								</td>
								<td>
									<p>Not applicable</p>
								</td>
							</tr>
							<tr>
								<td>
									<p>Internet or other electronic network activity information (e.g., browsing history, search history, and information regarding a consumer’s interaction with an Internet Web site, application, or advertisement)</p>
								</td>
								<td>
									<p>Not applicable</p>
								</td>
								<td>
									<p>Not applicable</p>
								</td>
								<td>
									<p>Not applicable</p>
								</td>
							</tr>
							<tr>
								<td>
									<p>Geolocation data</p>
								</td>
								<td>
									<p>Not applicable</p>
								</td>
								<td>
									<p>Not applicable</p>
								</td>
								<td>
									<p>Not applicable</p>
								</td>
							</tr>
							<tr>
								<td>
									<p>Audio, electronic, visual, thermal, olfactory, or similar information (sensory data)</p>
								</td>
								<td>
									<p>Not applicable</p>
								</td>
								<td>
									<p>Not applicable</p>
								</td>
								<td>
									<p>Not applicable</p>
								</td>
							</tr>
							<tr>
								<td>
									<p>Professional or employment-related information</p>
								</td>
								<td>
									<p>Not applicable</p>
								</td>
								<td>
									<p>Not applicable</p>
								</td>
								<td>
									<p>Not applicable</p>
								</td>
							</tr>
							<tr>
								<td>
									<p>Education information</p>
								</td>
								<td>
									<p>Not applicable</p>
								</td>
								<td>
									<p>Not applicable</p>
								</td>
								<td>
									<p>Not applicable</p>
								</td>
							</tr>
							<tr>
								<td>
									<p>Inferences drawn from any of the information identified above to create a profile about a consumer reflecting their preferences, characteristics, psychological trends, predispositions, behavior, attitudes, intelligence, abilities, and aptitudes</p>
								</td>
								<td>
									<p>Not applicable</p>
								</td>
								<td>
									<p>Not applicable</p>
								</td>
								<td>
									<p>Not applicable</p>
								</td>
							</tr>
							<tr>
								<td>
									<p>Sensitive personal information</p>
								</td>
								<td>
									<p>Not applicable</p>
								</td>
								<td>
									<p>Not applicable</p>
								</td>
								<td>
									<p>Not applicable</p>
								</td>
							</tr>
							</tbody>
						</Table>
						<p>
						As noted in the above chart, we collect the above personal information directly from you via the Site, and from your interactions with the Site, including any personal information which you provide directly or indirectly.

						</p>
						<p><b>Directly</b></p>
						<p>
						Through the Site: We collect any personal information which you submit directly to the Site, including any comments and information you submit in conjunction with the content and articles featured on the Site, and all personal information that you elect to submit through the Site (by commenting and/or subscribing). Specifically, we may collect your name, surname, email address, etc. To the extent that you contact us through the Site or through another method (email or mail), we also collect the personal information that you submit in conjunction with your communication or request, including but not limited to your name and email address. 

						</p>
						<p>
							<b>Indirectly</b>
						</p>
						<p>
						Cookies and Tracking Pixels on the Site: We may elect to use and store cookies and tracking pixels on the Site, as described further. A cookie is a small data text file containing an identifier (a string of letters and numbers) that is sent by a web server to a web browser, and which is stored on your computer’s web browser. The identifier is then sent back to the server each time the browser requests a page from the server. Cookies can later be retrieved to identify you to us. Personal information that we store about you may be linked to the information stored in and obtained from cookies. Cookies make your use of the Site easier, make the Site run more smoothly, and help us to maintain a secure Site. Tracking pixels (also known as web beacons, 1×1 pixels, or clear GIFs) are code requiring a call back to a server to render, and which deliver information to us about your IP address and information from your browser. Cookies and tracking pixels are used to track your interaction with the Site, including clicks. Cookies and tracking pixels may collect information about you, such as your IP address, domain server, mobile and/or operating device identifier, clickstream data, computer operating system, source URL, referring and exit pages, web browser version and type, pages viewed, ISP, data and time stamp, mobile carrier, time spent on the site, behavioral data (opens, clicks), and user-specific and aggregate information on areas of the Site used. We also may collect information on whether you responded to a particular offer/advertisement, or a particular product or service featured on the Site, and which links you click through the Site through the use of tracking pixels.
						</p>
						<p>Cookies and Tracking Pixels in Our Emails: We may automatically collect information when an individual opens one of our emails, through the use of cookies and tracking pixels. We also may collect information on whether the individual clicked on any links within such emails. To the extent that you click on a particular product or service featured on the Site or in any email communication or newsletter, We may collect information on which link you clicked. If you request to unsubscribe from receiving emails We may add your email address to our suppression file, to comply with CAN-SPAM.
</p>
						<p>
							<b>Tracking and How We Respond to Do Not Track Signals</b>
						</p>
						<p>
						You can instruct your web browser to refuse all cookies or to indicate when a cookie is being sent, by visiting the preferences or settings page of your web browser. In the event that your browser requests that we do not track you or store a cookie, unless otherwise required by law to honor the requests, we do not guarantee that our Site will honor such requests. Please note that we do not guarantee that the Site will work properly in the event that you elect to opt out of our tracking or use of cookies
						</p>
						<h4 className="pb-2 pt-3">How We Use Collected Personal Information</h4>
						<p>
							We may use information collected through your use of the Site to:
						</p>
						<ul style={{
							fontFamily: "Gilroy-Regular",
							fontSize: "16px !important",
							color: "#9798aa"
						}}>
							<li>Provide you with the ability to access and interact with the Site;</li>
							<li>Maintain your account;</li>
							<li>Track Site access and interaction;</li>
							<li>Fulfill requests submitted through the Site;</li>
							<li>Personalize and improve the Site;</li>
							<li>Advertising/marketing;</li>
							<li>Update our internal database;</li>
							<li>Analytics;</li>
							<li>Connect you with the particular products and/or services featured on the Site;</li>
							<li>Detect security incidents, and protect against malicious, deceptive, fraudulent, or
								illegal activity;
							</li>
							<li>Debug to identify and repair errors that affect functionality of the Site;</li>
							<li>Track consumer interaction with the Site (counting ad impressions, verifying
								positioning and quality of ad impressions, etc.);
							</li>
							<li>Internal research;</li>
							<li>Verify or maintain quality and safety; and</li>
							<li>Legal compliance</li>
						</ul>
						<p>
						We may collect and use non-personally identifiable information about you through cookies and tracking pixels, including the keywords leading you to the Site and the referring website address and whether you responded to a particular offer/advertisement featured on the Site or any email promoting the Site. We also may collect information when an individual opens an email marketing the Site, and/or clicks on any links within such emails, through the use of cookies and tracking pixels. As noted above, in the event that your web browser requests that we do not track you or store a cookie, unless otherwise required by law to do so, we do not guarantee that our Site will honor such requests.
						</p>
						<h4 className="pb-2 pt-3">From Third Parties </h4>
						<p>As noted above in the chart, in addition to collecting personal information through the Site and our internal algorithms, we also collect personal information through data analytics providers/web analytics services and data resellers/brokers.</p>
						<h4 className="pb-2 pt-3">With Whom We May Share Your Information</h4>
						<p>
						If you request to unsubscribe from receiving email newsletters from us, we may share your email address internally with our parent company and any subsidiary and affiliated companies, to effectuate your request to unsubscribe and to comply with CAN-SPAM. We do not share your information with any non-affiliated third parties. </p>
						<p>
						On occasion, we may be required by law enforcement or judicial authorities to provide information to the appropriate governmental authorities. In the event of a merger, acquisition, or sale of our business, your data may also be transferred. In the event we become the subject of an insolvency proceeding, whether voluntary or involuntary, we or our liquidator, administrator, receiver or administrative receiver may sell, license or otherwise dispose of, such information in a transaction approved by the court.

						</p>
						<h4 className="pb-2 pt-3">Security</h4>
						<p>
						The security of your data is important to us but remember that no method of transmission over the Internet or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your data, we cannot guarantee its absolute security. As a result, you acknowledge that there are security and privacy limitations of the Internet which are beyond our control; the security, integrity and privacy of any and all information and data exchanged between you and us through this Site cannot be guaranteed; and any such information and data may be viewed or tampered with in transit by a third party. We make no warranty, express, implied, or otherwise, regarding the security of the Site and your personal information.

						</p>
						<h4 className="pb-2 pt-3">Third Party Links and Advertisements</h4>
						<p>
						The Site may contain links to third party websites. We are not responsible for the privacy practices or the content of any such third party websites. This Privacy Policy only addresses our use and disclosure of information which we collect from you. Any third party websites which are accessible through our Site, and companies to whom your information is transferred, have their own privacy policies and data collection, use and disclosure practices. Please consult each third party website’s privacy policy. We are not responsible for the policies or practices of third parties or their sites. Additionally, third party advertisers and lead purchasers featured on the Site may collect information about you when you view or click on their advertising displayed on the Site or in emails, through the use of cookies and tracking pixels. We cannot control this collection of information. You should contact these third parties directly if you have any questions about their use of the information that they collect.
						</p>
						<h4 className="pb-2 pt-3">Children</h4>
						<p>
						You must be an adult of 18 years or older to use the Site. We do not target the Site to children. Consistent with the federal Children’s Online Privacy Protection Act of 1998 (“COPPA”), we will never knowingly collect personal information from anyone under the age of 13 without requesting parental consent. If you are aware that your minor child under the age of 13 has provided us with personal information, please contact us.
						</p>
						<h4 className="pb-2 pt-3">United States Only</h4>
						<p>
						This Site is intended to be used in the United States only. Your personal information may be transferred to and maintained on computers located outside of your state, country, or other governmental jurisdiction where the data protection laws may differ from those of your jurisdiction. If you are located outside United States, please note that the personal information we collect through the Site may be processed in and outside of the United States. Please note that data protection and other applicable laws of the United States may not be as comprehensive as those laws or regulations in your country or may otherwise differ from the data protection or consumer protection laws in your country. By using the Site, you consent to the transfer of your personal information as described in this Privacy Policy.

						</p>
						<h4 className="pb-2 pt-3" id="california-residents">California Residents – Your Privacy
							Rights</h4>
						<p>
							<i>California Rights Under Shine the Light</i>
						</p>
						<p>
						Under California’s Shine the Light law (California Civil Code § 1789.83), California residents have the right to request, once a year, a list of the categories of personal information disclosed to third parties for their direct marketing purposes during the immediately preceding calendar year; and the names and addresses of such third parties with whom we have shared the personal information. We do not disclose personal information about consumers for a business purpose to third parties. We may only share personal information as identified under the “With Whom We May Share Your Information” section above in the preceding twelve (12) months. California customers may request information, by submitting a request to us via email at [contact email]. This request may be made no more than once per customer per calendar year. We reserve our right not to respond to requests that are not submitted in accordance with this section.
						</p>
						<p>
							<i>California Rights Under the CCPA</i>
						</p>
						<p>
						In addition, if you are a California resident under the meaning of Section 17014 of Title 18 of the Cal. Code of Regulations, your use of the Site is subject to the California Consumer Privacy Act (“CCPA”), and all final, implementing regulations. 
Under the California Consumer Privacy Act of 2018 (“CCPA”) (Cal. Civ. Code § 1798.100 et seq.), a consumer has the following rights:
						</p>
						<p>- Notice at Collection: at or before the point of collection, the right to receive notice of our practices, including the categories of personal information and any sensitive personal information to be collected; the purposes for which such information is collected or used; whether such information is sold or shared; and the length of time that such information is retained. This information is found in this notice.
</p>
						<p>- Right to Know What Personal Information is Collected & Right to Access Personal Information: The right to know what personal information the business has collected about the consumer, including the categories of personal information, categories of sources from which the personal information is collected, the business or commercial purpose for collecting, selling, or sharing personal information, the categories of third parties to whom the business discloses personal information, and the specific pieces of personal information the business has collected about the consumer;
</p>
						<p>- Right to Delete: The right to request that a business delete personal information that the business has collected from the consumer, subject to certain exceptions;
</p>
						<p>- Right to Correct: The right to correct inaccurate personal information that a business maintains about a consumer;</p>
						<p>- Right to Opt-Out of the Sale or Sharing of Personal Information: if the business sells or shares personal information, the right to opt-out of the sale or sharing of personal information by the business;</p>
						<p>- Right to Limit the Use or Disclosure of Sensitive Personal Information: if the business uses or discloses sensitive personal information for reasons other than those in § 7027(m), the right to limit the use or disclosure of such sensitive personal information; and</p>
						<p>- Right to No Discrimination: The right not to receive discriminatory treatment by the business for the exercise of CCPA privacy rights.</p>
						<p>Information regarding our collection, disclosure, and sale/sharing practices are detailed below, in addition to instructions on how to exercise your CCPA rights.</p>
						<h4 className="pb-2 pt-3">What Personal Information Do We Collect?</h4>
						<p>
							We have collected personal information about consumers within the preceding twelve (12) months as detailed above under the “Personal Information We Collect About You” section.
						</p>
						<h4 className="pb-2 pt-3">What Personal Information Do We Disclose?</h4>
						<p>
						We do not disclose personal information about consumers for a business purpose to third parties. We may share personal information as identified under the “With Whom We May Share Your Information” section above in the preceding twelve (12) months. We do not use or disclose sensitive personal information.

						</p>
						
						<h4 className="pb-2 pt-3">What Personal Information Do We Sell or Share?</h4>
						<p>
						We do not sell or share personal information with any non-affiliated third parties; we only share personal information internally, as stated above.
In the event that We, or substantially all of Our assets, are acquired, such personal information may be included within the transferred assets. In the event We become the subject of an insolvency proceeding, whether voluntary or involuntary, We or Our liquidator, administrator, receiver or administrative receiver may sell, license or otherwise dispose of, such information in a transaction approved by the court.
We do not have any actual knowledge of the sale of personal information of minors under 16 years of age. There are no financial incentives to disclose.

						</p>
						<h4 className="pb-2 pt-3">How to Submit a Request Under the CCPA</h4>
						<p>
						To submit a request to know, request to delete, and/or request to correct, please email us at [contact email]. To process your request, we may require that you submit the following information in conjunction with your request: your first name, last name, a California address, and email address. The information which you provide in conjunction with your request will be used to verify your request and to attempt to identify you within our data records. The information which you submit in conjunction with your request to know will be used for the express purposes of verification and providing you with responsive information to your request, if required. Please note that we utilize a two-step process for requests to delete, whereby you first submit your request to delete, and second, you separately confirm that you want your personal information deleted. The CCPA permits requests to know regarding collection and sale or sharing from the same consumer no more than twice in a twelve (12) month period.
						</p>
						
						<h4 className="pb-2 pt-3">How an Authorized Agent Can Make a Request Under the CCPA
</h4>
						<p>
						If you elect to use an authorized agent to exercise your right to submit a request to know, request to delete, and/or request to correct, before processing the request, we require the purported authorized agent to provide proof of your signed permission to submit the request (unless there is a power of attorney in place pursuant to Probate Code §§ 4121-4130). We also require that you directly verify your own identity with us, and directly confirm with us that you provided the authorized agent permission to submit the request on your behalf. The information submitted will not be used for any purposes other than to verify the request, fulfill the request, and fraud prevention.
						</p>
						<h4 className="pb-2 pt-3">Colorado Residents– Your Privacy Rights
</h4>
						<p>
						Colorado residents have the following data rights under the CPA:
						</p>
						<p>- Right to opt-out: of the processing of personal data for targeted advertising, sale of personal data, or profiling in furtherance of decisions that produce legal or similarly significant effects concerning a consumer;
</p>
						<p>- Right to access: may request confirmation on whether we process personal data concerning the consumer and to access the consumer’s personal data;</p>
						<p>- Right to correction: may correct inaccuracies in the consumer’s personal data, taking into account the nature of the personal data and the purposes of the processing;</p>
						<p>- Right to deletion: may delete personal data;</p>
						<p>- Right to data portability: in conjunction with the right to access, a consumer may obtain the personal data in a portable and, to the extent technically feasible, readily usable format</p>
						<p>Colorado residents that wish to exercise any of the above data rights under the CPA may submit a request that specifies the right(s) they wish to invoke by emailing us at [contact email]. We require the following information to authenticate your identity: your first and last name and a Colorado address. Authorized agents of Colorado residents may submit a request in the same manner. Please note that we must be able to authenticate, with commercially reasonable effort, the identity of the consumer and the authorized agent’s authority to act on such consumer’s behalf, by requiring the following information: the Colorado resident’s first and last name and Colorado address, and proof that such Colorado resident has authorized the agent to submit a request on their behalf.</p>
						<p>Our contact information is below. To the extent that we decline to act upon your request, you may appeal by emailing us at [contact email]. You may contact the Attorney General if you have concerns about the result of the appeal.</p>
						<h4 className="pb-2 pt-3">Connecticut Residents– Your Privacy Rights</h4>
						<p>
						Pursuant to the Connecticut Act Concerning Personal Data Privacy and Online Monitoring (“CTDPA”), the above identified categories of personal data are processed, for the above identified purposes. 

						</p>
						<p>As noted above, We do not sell your information with any non-affiliated third parties. We may share personal information as identified under the “With Whom We May Share Your Information” section above in the preceding twelve (12) months. To opt out, please email us at [contact email]. 

						</p>
						<p>Connecticut residents have the following rights under the CTDPA:</p>
						<p>- Right to confirmation and access: Confirm whether or not a controller is processing the consumer’s personal data and access such data (unless confirmation or access would require the controller to reveal a trade secret);</p>
						<p>- Right to correct: may correct inaccuracies in the consumer’s personal data;</p>
						<p>- Right to delete: may delete personal data provided by, or obtained about, the consumer;</p>
						<p>- Right to data portability: may obtain a copy in a portable and, to the extent technically feasible, readily usable format that allows consumer to transmit data to another controller without hindrance, where processing is carried out by automated means, provided that controller shall not be required to reveal any trade secret; and</p>
						<p>- Right to opt-out of certain data processing: may opt-out of the processing of personal data for targeted advertising, sale of personal data, or profiling in furtherance of solely automated decisions that produce legal or similarly significant effects concerning the consumer.</p>
						<p>Connecticut residents that wish to exercise any of the above rights under the CTDPA may submit a request that specifies the right(s) they wish to invoke by emailing us at [contact email]. You are permitted to designate an authorized agent. Information provided in response to a consumer request shall be provided free of charge, once per consumer during any twelve-month period. To the extent that we decline to act upon your request, you may appeal by emailing us at [contact email].</p>

						<h4 className="pb-2 pt-3">Nevada Residents – Your Privacy Rights</h4>
						<p>
						This notice is effective as of the effective date noted at the top of the Privacy Policy. Under the definition of “Sale” in the Nevada Revised Statutes, we do not sell your covered/personal information. The above identified categories of personal data are collected through the Site, as described in the “Personal Information We Collect About You” section above. We may post material changes to this notice, as described in the “Updates” section below. If you are a Nevada resident and would like to make a request under Chapter 603A of the Nevada Revised Statutes, including a request to review and request changes to your covered information, please email Us at [contact email].
						</p>
						<h4 className="pb-2 pt-3">Oregon Residents – Your Privacy Rights</h4>
						<p>
						Pursuant to the Oregon Consumer Privacy Act (“OCPA”) (ORS 646A.570-646A.589), the above identified categories of personal data are processed, for the above identified purposes. We do not share your information with any non-affiliated third parties.
						</p>
						<p>- Right to access: may request confirmation on whether we are processing or have processed the consumer’s personal data and obtain a copy of such personal data;</p>
						<p>- Right to correct: may correct inaccuracies in the consumer’s personal data, taking into account the nature of the personal data and the purposes of the processing;
</p>
						<p>- Right to delete: may delete personal data, including personal data the consumer provided to the controller, personal data the controller obtained from another source and derived data;
</p>
						<p>- Right to opt-out of certain data processing: may opt-out of the processing of personal data for targeted advertising, sale of personal data, or profiling the consumer in furtherance of decisions that produce legal effects or effects of similar significance.
</p>
						<p>Oregon residents that wish to exercise any of the above rights under the OCPA may submit a request to us that specifies the right(s) you wish to invoke by emailing us at [contact email]. To the extent that we decline to act upon your request, you may appeal by emailing us at [contact email]. 
 </p>
						<h4 className="pb-2 pt-3">Utah Residents – Your Privacy Rights </h4>
						<p>
						Pursuant to the Utah Consumer Privacy Act (“UCPA”) (§ 13-61-101 et seq.), the above identified categories of personal data are processed, for the above identified purposes. We do not share your information with any non-affiliated third parties.
						</p>
						<p>
						Consumers have the following rights under the UCPA:
						</p>
						<p>- Right to access: may request confirmation whether a controller is processing the consumer’s personal data; and access the consumer’s personal data;</p>
						<p>- Right to delete: may delete the consumer’s personal data;</p>
						<p>- Right to obtain a copy/portability: may obtain a copy of the consumer’s personal data, that the consumer previously provided to the controller, in a format that: to the extent technically feasible, is portable; to the extent practicable, is readily usable; and allows the consumer to transmit the data to another controller without impediment, where the processing is carried out by automated means; and </p>
						<p>- Right to opt-out of certain data processing: may opt-out of the processing of personal data for purposes of targeted advertising, or the sale of personal data.</p>
						<p>Utah consumers that wish to exercise any of the above rights under the UCPA may submit a request that specifies the right they wish to exercise by emailing us at [contact email]. We require the following information to authenticate your identity: your first and last name and a Utah address. Please note that We must be able to authenticate a consumer request to exercise a right under the UCPA, using commercially reasonable efforts.  </p>
						<h4 className="pb-2 pt-3">Virginia Residents – Your Privacy Rights</h4>
						<p>Pursuant to the Virginia Consumer Data Protection Act (“CDPA”) (Code of Virginia § 59.1-571 et seq.), the above identified categories of personal data are processed, for the above identified purposes. We do not share your information with any non-affiliated third parties.</p>
						<p>Virginia residents have the following rights under the CDPA:</p>
						<p>- Right to access: may request confirmation on whether we process the consumer’s personal data and obtain access such personal data;</p>
						<p>- Right to correct: may correct inaccuracies in the consumer’s personal data;</p>
						<p>- Right to delete: may delete personal data;</p>
						<p>- Right to data portability: may obtain a copy in a portable and, to the extent technically feasible, readily usable format; and</p>
						<p>- Right to opt-out of certain data processing: may opt-out of the processing of personal data for targeted advertising, sale of personal data, or profiling.</p>
						<p>Virginia residents that wish to exercise any of the above rights under the CDPA may submit a request that specifies the right(s) they wish to invoke by emailing us at [contact email]. To the extent that we decline to act upon your request, you may appeal by emailing us regarding your appeal at [contact email]. </p>
						<h4 className="pb-2 pt-3">Updates</h4>
						<p>This Privacy Policy was published and last updated on November 6, 2024. We may update this Privacy Policy from time to time. Updates to this Privacy Policy are effective when they are posted. You acknowledge and agree that it is your responsibility to review this Site and this Privacy Policy periodically and to be aware of any modifications. Your use of the Site after such modifications will constitute your acknowledgment of the modified Privacy Policy; and agreement to abide and be bound by the modified Privacy Policy.</p>
						<h4 className="pb-2 pt-3">Contact Us</h4>
						<p>If you have any questions or concerns regarding your privacy rights or our Privacy Policy or practices, you may contact us via email at [contact email].</p>
						<h4 className="pb-2 pt-3">Need More Information or Do You Need Extra Help?</h4>
						<p>Further, if you have a disability which interferes with your ability to access this policy, we can make accommodations to present the policy in an alternative format to assist you. If you would like to receive this Privacy Policy in another format that is accessible to your needs (for example: audio, large print, etc.), please contact us at [contact email].</p>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
}

export default withRouter(PrivacyPolicy);
